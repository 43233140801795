<script>
import VueCircle from 'vue2-circle-progress/src/index.vue'
import foodList from "./foodList";
import {mapState} from "vuex";
import draggable from 'vuedraggable'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import VueTagsInput from "@johmun/vue-tags-input";

export default {
        components: {
            VueCircle, foodList, draggable, quillEditor, VueTagsInput
        },
        props: {
            mealId: {
                default:  null,
                type: Number,
            },
            dietPlanId: {
                default:  null,
                type: String,
            },
            foods: {
                default: () => {return []},
                type:Array
            },
            dietPlanFunc: {
                default: () => {return {}},
                type: Object
            },
            dietPlanStats:{
                default: () => {return []},
                type: Array
            },
            microNutrients:{
                default: () => {return {}},
                type: Object
            },
            isVegan: {
                default: false,
                type: Boolean
            },
            isFasting:{
                default: false,
                type: Boolean
            },
            isCarbohydration:{
                default: false,
                type: Boolean
            },
            carbohydrationDay:{
                default: 0,
                type: Number
            },
            isMealTemplate: {
                default: false,
                type: Boolean
            },
            isRecipe: {
              default: false,
              type: Boolean
            },
            foodToAvoid:{
              // eslint-disable-next-line vue/require-valid-default-prop
              default: () => {return {}},
              type: Object
            }
        },
        data() {
            return {
                searchKeyWord: '',
                searchCategory: null,
                localFoods: [],
                mealFoods: [],
                statistics: {},
                mealStatistics: {},
                requiredKcal: 0,
                meal: {
                    notes: null
                },
                timer: null,
                updatingIsodunama: false,
                fetchingFoods: true,
                updatingFoodOrder: false,
                initialNotes: null,
              validation: [{
                classes: 'min-length',
                rule: tag => tag.text.length < 2,
              }],
              tags: [],
              tag: ''
            }
        },

        computed:{

            ...mapState({
              dris: state => state.food._dris,
              mealTemplateTags: state => state.tags._mealTemplateTags
            }),
            filteredItems() {
              return this.mealTemplateTags ? this.mealTemplateTags.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
              }) : [];
            },

            getAllMealFoods(){
                return this.mealFoods
            },
            calculateCaloriesTitle(){
                if(this.isCarbohydration && this.carbohydrationDay === this.meal.day && this.dietPlanFunc.weight > 0) {
                    let requiredKcal =  (this.dietPlanFunc.weight * 10*4 + this.dietPlanFunc.weight * 1.2*4 + this.dietPlanFunc.weight * 0.9*9);
                    return this.dietPlanStats[this.meal.day].stats.calories + 'kcal / ' + requiredKcal.toFixed(2) + 'kcal';
                }
                return this.dietPlanStats[this.meal.day].stats.calories + 'kcal / ' + this.requiredKcal.toFixed(2) + 'kcal';
            },

            calculateCaloriesValue(){
                if(this.isCarbohydration && this.carbohydrationDay === this.meal.day && this.dietPlanFunc.weight > 0) {
                    let requiredKcal =  (this.dietPlanFunc.weight * 10*4 + this.dietPlanFunc.weight * 1.2*4 + this.dietPlanFunc.weight * 0.9*9);
                    return this.dietPlanStats[this.meal.day].stats.calories / requiredKcal.toFixed(2)*100;
                }
                return this.dietPlanStats[this.meal.day].stats.calories / this.requiredKcal.toFixed(2)*100;
            },
            calculateCaloriesPercentage(){
                if(this.isCarbohydration && this.carbohydrationDay === this.meal.day && this.dietPlanFunc.weight > 0) {
                    let requiredKcal =  (this.dietPlanFunc.weight * 10*4 + this.dietPlanFunc.weight * 1.2*4 + this.dietPlanFunc.weight * 0.9*9);
                    return (this.dietPlanStats[this.meal.day].stats.calories / requiredKcal.toFixed(2)*100).toFixed(0) + '%';
                }
                return (this.dietPlanStats[this.meal.day].stats.calories / this.requiredKcal.toFixed(2) * 100).toFixed(0) + '%';
            },
        },

        created(){
            this.localFoods = this.foods;
            this.localFoods.map( x => {
                x.equations = 1;
            });
            this.requiredKcal = this.dietPlanFunc.requiredKcal;
            this.fetchMeal();
            this.fetchMealFoods();
            this.driObj = this.formatDriObject(this.dietPlanFunc.sex, this.dietPlanFunc.age);
            if(this.isMealTemplate && !this.isRecipe) this.fetchMealTemplateTags();
        },

        methods:{
            fetchMealTemplateTags(){
              this.$axios.get(`/meals/${this.mealId}/tag`).then(result => {
                this.tags = result.data;
              }).catch(e => {
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              })
            },
            addTag(obj){
              this.$axios.post(`/meals/${this.mealId}/tag`, {text: obj.tag.text}).then(result => {

                this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η ετικέτα προστέθηκε επιτυχώς!'});
                result.data.text = obj.tag.text;
                this.tag='';
                this.tags.push(result.data);
                this.$store.dispatch('tags/fetchCacheMealTemplateTags'); // re-fetch and store data!
              }).catch(e=>{
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η προσθήκη της ετικέτας απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
            },
            deleteTag(obj){
              this.$axios.delete(`/meals/${this.mealId}/tag/${obj.tag.tagId}`).then(result => {
                this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η ετικέτα διαγράφηκε επιτυχώς!'});
                this.tags.splice(obj.index, 1);
              }).catch( e  => {
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
            },

          setDragCursor(value) {
              const html = document.getElementsByTagName('html').item(0)
              html.classList.toggle('grabbing', value)
            },
            calculateNutrientDri(nutrient, value){
              if(this.dris[nutrient]){

                let dri;
                try{
                  dri = this.dris[nutrient][this.driObj.gender][this.driObj.age]['dri']
                }catch(e){
                  return '-'
                }

                let percDri = 100 * value / dri

                return  percDri.toFixed(0) + '% (' + dri + ')';
              }else{
                return '-'
              }
            },
            getFoodQuantity(mealFood){
              return +(mealFood.food.quantity.toFixed(2));
            },
            findFoodMeasure(food){
              let measure = this.foodMeasures.find(x=> x.id === food.measurementUnit);
              return measure.displayName ? measure.displayName : '';
            },

            fetchMeal(){
                let url = this.isMealTemplate ? `/meals/${this.mealId}` :`/dietPlan/${this.dietPlanId}/meals/${this.mealId}`;
                this.$axios.get(url).then(result => {
                    this.meal = result.data;
                    this.searchCategory = (this.meal.type + 1).toString();
                    if(this.searchCategory > 5){
                      this.searchCategory = "0";
                    }
                     this.initialNotes = result.data.notes;
                    if(this.dietPlanFunc.useExercise) this.requiredKcal += this.dietPlanFunc.caloricNeeds[this.meal.day];
                });

            },

            fetchMealFoods(isUpdated = false){

                if(isUpdated) this.$emit('food-changed', true);

                let url = this.isMealTemplate ? `/meals/${this.mealId}/foods` : `/dietPlan/${this.dietPlanId}/meals/${this.mealId}/foods`;

                this.$axios.get(url).then(result => {

                    // if we're updating the values (after adding or removing a food - changing its value)

                    // first we run an update on the stats, removing this meal's stats!
                    if(isUpdated && !this.isMealTemplate) this.updateMealStats();
                    // update meal food stats!
                    let mealFoods = result.data.mealFoods;
                    let totalStats = result.data.stats;

                    for(const meal of mealFoods){
                        meal.food = JSON.parse(JSON.stringify(this.foods.find(food => food.id === meal.foodId )));
                        meal.food.baseQuantity = meal.food.quantity;
                        meal.food.kcal *= meal.quantity;
                        meal.food.carbohydrates *= meal.quantity;
                        meal.food.proteins *=meal.quantity;
                        meal.food.fats *= meal.quantity;
                        meal.food.quantity *= meal.quantity;
                        meal.category = meal.food.category;

                        totalStats.calories += meal.food.kcal;
                        totalStats.carbs += meal.food.carbohydrates;
                        totalStats.proteins += meal.food.proteins;
                        totalStats.fats += meal.food.fats;

                        if(meal.food.list !== 0 ){
                            for(let nutrient of this.microNutrientFields){
                                if(!meal.food.microNutrients[nutrient]) continue;
                                if(!totalStats.microNutrients) totalStats.microNutrients = {};
                                if(!totalStats.microNutrients[nutrient]) totalStats.microNutrients[nutrient] = 0;
                                totalStats.microNutrients[nutrient] += meal.quantity * parseFloat(meal.food.microNutrients[nutrient]);
                            }
                        }
                    }

                    // then we assign the new values
                    this.mealFoods = mealFoods;
                    this.statistics = totalStats;
                    this.fetchingFoods = false;

                    if( !this.isMealTemplate && this.dietPlanFunc.nutrients.enableMealGoals){
                        setTimeout(() =>{
                            if(this.$refs.cbar) this.$refs.cbar.updateProgress(this.calculateDailyProgressBarValue('CARB'));
                            if(this.$refs.pbar) this.$refs.pbar.updateProgress(this.calculateDailyProgressBarValue('PROTEIN'));
                            if(this.$refs.fbar) this.$refs.fbar.updateProgress(this.calculateDailyProgressBarValue('FAT'));
                        }, 300);

                    }


                    // then, after we fetch and store the new data, we re-add the values so that it updates the spinner!
                    if(isUpdated &&  !this.isMealTemplate) this.updateMealStats(true);
                });

            },

            updateMealStats(shouldAdd = false){
                // called when we add or remove a food. It removes the day's stats from statistics, and then it re-adds after fetching the data!
                let currPlanStats = this.dietPlanStats[this.meal.day].stats;

                // in case we need to update the stats by ADDING values (happens after fetching new mealFoods)
                if(shouldAdd){
                    currPlanStats.calories += this.statistics.calories;
                    currPlanStats.carbs += this.statistics.carbs;
                    currPlanStats.proteins += this.statistics.proteins;
                    currPlanStats.fats += this.statistics.fats;
                    for(let nutrient of Object.keys(this.statistics.microNutrients)){
                        if(!currPlanStats.microNutrients[nutrient]) currPlanStats.microNutrients[nutrient] = this.statistics.microNutrients[nutrient];
                        else currPlanStats.microNutrients[nutrient] += parseFloat(this.statistics.microNutrients[nutrient]);
                    }

                }else{
                    // in case we deduct the stats before adding a new food!
                    currPlanStats.calories -= this.statistics.calories;
                    currPlanStats.carbs -= this.statistics.carbs;
                    currPlanStats.proteins -= this.statistics.proteins;
                    currPlanStats.fats -= this.statistics.fats;
                    for(let nutrient of Object.keys(this.statistics.microNutrients)){
                        if(!currPlanStats.microNutrients[nutrient]) continue;
                        else currPlanStats.microNutrients[nutrient] -= parseFloat(this.statistics.microNutrients[nutrient]);
                    }
                }
                this.dietPlanStats[this.meal.day].stats = currPlanStats;

            },
            removeMealFood(mealFood){
                this.$emit('remove-food', mealFood);
                let url = this.isMealTemplate ? `/meals/${this.mealId}/foods/${mealFood.id}` : `/dietPlan/${this.dietPlanId}/meals/${this.mealId}/foods/${mealFood.id}`;
                this.$axios.delete(url).then(result=>{
                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το τρόφιμο αφαιρέθηκε επιτυχώς!'});
                    this.fetchMealFoods(true);
                }).catch( e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η αφαίρεση του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },
            updateNotesApiCall({closeAfter = false}){

              if(this.meal.notes === this.initialNotes) return; // don't save if we have the saem exact data.
              let url = this.isMealTemplate ? `/meals/${this.mealId}/` : `/dietPlan/${this.dietPlanId}/meals/${this.mealId}/`;
              this.$axios.put(url, {notes: this.meal.notes}).then(result=>{
                this.initialNotes = this.meal.notes;
                this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Οι σημειώσεις ενημερώθηκαν επιτυχώς!'});
                if(closeAfter && this.meal.isTextOnly) this.$emit('closeDialog');
              }).catch(e=>{
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Κάτι πήγε λάθος στην ανανέωση των δεδομένων. Παρακαλώ δοκιμάστε ξανά!'});
              });
            },

            updateMealFoodQuantity(item, action){
                if(action === 'down' && !((item.quantity - 0.5)>=0.5 )){
                    return;
                }
                if(action === 'down'){
                    item.quantity -=0.5;
                }
                if(action === 'up'){
                    item.quantity += 0.5;
                }

                if(!item.quantity > 0){
                    return;
                }
                this.updatingIsodunama = true;
                let url = this.isMealTemplate ? `/meals/${this.mealId}/foods/${item.id}` : `/dietPlan/${this.dietPlanId}/meals/${this.mealId}/foods/${item.id}`;
                this.$axios.put(url, {mealFoodId: item.id, quantity: item.quantity}).then(result=>{
                    this.fetchMealFoods(true);
                    this.updatingIsodunama = false;
                }).catch( e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση των ισοδυνάμων του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            updateMealFoodQuantityByQuantity(value, item){

              if(value  === 0) return;
              item.quantity = (+parseFloat(value).toFixed(2)) / item.food.baseQuantity;
              this.updateMealFoodQuantity(item);

            },

            updateMealFoodName(item){
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }

                this.timer = setTimeout(() => {
                    let url = this.isMealTemplate ? `/meals/${this.mealId}/foods/${item.id}` : `/dietPlan/${this.dietPlanId}/meals/${this.mealId}/foods/${item.id}`
                    this.$axios.put(url, {mealFoodId: item.id, altName: item.altName}).then(()=>{
                        this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: 'Το όνομα αποθηκεύτηκε επιτυχώς!'});
                    }).catch( e => {
                        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση των ισοδυνάμων του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                    });
                }, 1000);
            },

            updateEditMealFoodName(item){
                let url = this.isMealTemplate ? `/meals/${this.mealId}/foods/${item.id}/edit-name` : `/dietPlan/${this.dietPlanId}/meals/${this.mealId}/foods/${item.id}/edit-name`;
                this.$axios.put(url, {mealFoodId: item.id}).then((result)=>{
                    item.hasAltName = result.data;
                }).catch( e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση των ισοδυνάμων του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            updateHideMealFoodQuantity(item){
              this.updatingIsodunama = true;
              let url = this.isMealTemplate ? `/meals/${this.mealId}/foods/${item.id}/edit-quantity` : `/dietPlan/${this.dietPlanId}/meals/${this.mealId}/foods/${item.id}/edit-quantity`;
              this.$axios.put(url, {mealFoodId: item.id}).then((result)=>{
                item.hideQuantity = result.data;
                this.updatingIsodunama = false;
              }).catch( e => {
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση των ισοδυνάμων του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
            },

            calculateProgressBarTitle(type){
                let value;

                if(type === 'CARB') value = this.dietPlanStats[this.meal.day].stats.carbs;
                if(type === 'PROTEIN') value = this.dietPlanStats[this.meal.day].stats.proteins;
                if(type === 'FAT') value = this.dietPlanStats[this.meal.day].stats.fats;

                let divide;

                if(this.isCarbohydration && this.carbohydrationDay === this.meal.day) {
                    if(type === 'CARB') divide = (this.dietPlanFunc.weight * 10).toFixed(2);
                    if(type === 'PROTEIN')  divide = (this.dietPlanFunc.weight * 1.2).toFixed(2);
                    if(type === 'FAT')  divide = (this.dietPlanFunc.weight * 0.9).toFixed(2);

                }else{
                    if(type === 'CARB') divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.carbs.total / 100)/4).toFixed(2);
                    if(type === 'PROTEIN')  divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.proteins.total / 100)/4).toFixed(2);
                    if(type === 'FAT')  divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.fats.total / 100)/9).toFixed(2);
                }




                return value.toFixed(2) + 'g / ' + divide + 'g';
            },
            calculateProgressBarValue(type){
                let value;

                if(type === 'CARB') value = this.dietPlanStats[this.meal.day].stats.carbs;
                if(type === 'PROTEIN') value = this.dietPlanStats[this.meal.day].stats.proteins;
                if(type === 'FAT') value = this.dietPlanStats[this.meal.day].stats.fats;

                let divide;

                if(this.isCarbohydration && this.carbohydrationDay === this.meal.day) {
                    if(type === 'CARB') divide = (this.dietPlanFunc.weight * 10).toFixed(2);
                    if(type === 'PROTEIN')  divide = (this.dietPlanFunc.weight * 1.2).toFixed(2);
                    if(type === 'FAT')  divide = (this.dietPlanFunc.weight * 0.9).toFixed(2);

                }else{
                    if(type === 'CARB') divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.carbs.total / 100)/4).toFixed(2);
                    if(type === 'PROTEIN')  divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.proteins.total / 100)/4).toFixed(2);
                    if(type === 'FAT')  divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.fats.total / 100)/9).toFixed(2);
                }

                return value *100 / divide;
            },
            calculateProgressBarLabel(type){
                let value;

                if(type === 'CARB') value = this.dietPlanStats[this.meal.day].stats.carbs;
                if(type === 'PROTEIN') value = this.dietPlanStats[this.meal.day].stats.proteins;
                if(type === 'FAT') value = this.dietPlanStats[this.meal.day].stats.fats;

                let divide;


                if(this.isCarbohydration && this.carbohydrationDay === this.meal.day) {
                    if(type === 'CARB') divide = (this.dietPlanFunc.weight * 10).toFixed(2);
                    if(type === 'PROTEIN')  divide = (this.dietPlanFunc.weight * 1.2).toFixed(2);
                    if(type === 'FAT')  divide = (this.dietPlanFunc.weight * 0.9).toFixed(2);

                }else{
                    if(type === 'CARB') divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.carbs.total / 100)/4).toFixed(2);
                    if(type === 'PROTEIN')  divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.proteins.total / 100)/4).toFixed(2);
                    if(type === 'FAT')  divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.fats.total / 100)/9).toFixed(2);

                }



                return (value *100 / divide).toFixed(0) + '%';
            },

            calculateDailyProgressBarValue(type){

                let currVal;
                let divide;
                let nutrientVals;
                let mealType = this.meal.type;

                if(type === 'CARB'){
                    currVal = this.statistics.carbs;
                    nutrientVals = this.dietPlanFunc.nutrients.carbs;
                    divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.carbs.total / 100)/4).toFixed(2);
                }
                if(type === 'PROTEIN'){
                    currVal = this.statistics.proteins;
                    nutrientVals = this.dietPlanFunc.nutrients.proteins;
                    divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.proteins.total / 100)/4).toFixed(2);
                }
                if(type === 'FAT'){
                    currVal = this.statistics.fats;
                    nutrientVals = this.dietPlanFunc.nutrients.fats;
                    divide = ((this.requiredKcal * this.dietPlanFunc.nutrients.fats.total / 100)/9).toFixed(2);
                }

                if(this.isCarbohydration && this.carbohydrationDay === this.meal.day) {
                    if(type === 'CARB') divide = (this.dietPlanFunc.weight * 10).toFixed(2);
                    if(type === 'PROTEIN')  divide = (this.dietPlanFunc.weight * 1.2).toFixed(2);
                    if(type === 'FAT')  divide = (this.dietPlanFunc.weight * 0.9).toFixed(2);
                }


                if(mealType===0) divide = divide * nutrientVals.breakfast /100;
                if(mealType===1) divide = divide * nutrientVals.dekatiano /100;
                if(mealType===2) divide = divide * nutrientVals.mesimeriano /100;
                if(mealType===3) divide = divide * nutrientVals.apogevmatino /100;
                if(mealType===4) divide = divide * nutrientVals.vradino /100;

                if(divide===0) return 100;
                return (currVal*100/divide).toFixed(0) > 0 ? parseInt((currVal*100/divide).toFixed(0))  : 0;
            },
          updateOrderOnDb(){
              this.updatingFoodOrder = true;
              let newOrders = [];
              for(const [index, value] of this.mealFoods.entries()){
                this.mealFoods[index].order = index; // save locally
                newOrders.push({id: value.id, order: index})
              }
            let url = `/meals/foods/re-order`;
            this.$axios.put(url, {ordering: newOrders}).then(res=>{}).catch(e=>{
              this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η αποθήκευση της σειράς των τροφίμων απέτυχε!'});
            }).finally(()=>{

              this.updatingFoodOrder = false;
            })

          }


        }
    }
</script>
<style lang="scss">
    @import '@src/design/custom/pages/_dietPlan.scss';
    .food-q{
      width:140px;
      .input-group-text{
        padding:0.1rem 0.4rem;
        font-size: 0.75rem;
      }
    }
    .food-wrapping{
      .card{
        padding:0;
      }
      .card-body{
        padding:1em 0.5em!important;
        .row{
          > div{
            padding: 0 7.5px;
          }
        }
      }
    }
    .scrollbar-tools{
      .btn-block{
        padding:0;
      }
    }

    img{
        user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
    #add-food-modal{
        .edit-name{
            cursor:pointer;
            color:#3155A6;
        }
        .active-edit-name{
            color:white;
            background-color:#3155A6;
            padding: 2px 6px;
            border-radius:99px;
            /*font-size:0.7rem;*/
        }
        .edit-name-form{
            width:90%;
        }
    }

    .handle{
      cursor: grabbing!important;
    }
</style>
<template>
<!-- existing food -->
<div id="add-food-modal" >
  <div v-if="!meal.isTextOnly" class="row">
    <div v-if="dietPlanStats[meal.day] || isMealTemplate" class="col-md-7">

      <div class="food-wrapping p-2">
        <b-overlay :show="updatingFoodOrder" rounded="sm">
          <draggable v-model="mealFoods" @update="updateOrderOnDb" handle=".handle">
            <b-card v-for="mealFd in getAllMealFoods" :key="'ml-'+mealFd.id" :class="['category-' + mealFd.food.category, ((isVegan && !mealFd.food.isVegan) || (isFasting && !mealFd.food.isFasting) ) ? 'food-error' : '']"
                    class="shadow-sm">
              <b-card-text>
                <div class="row align-items-center">
                  <div class="col-12 col-xl-3 h-100 align-self-center handle pl-2">
                    <span class="edit-name float-right" :class="mealFd.hasAltName ? 'active-edit-name' : ''" @click="updateEditMealFoodName(mealFd)">
                        <i class="icon-pencil"></i>
                    </span>
                    <span v-if="!mealFd.hasAltName" class="small-size">

                      {{mealFd.food.name}}</span>
                    <span v-if="mealFd.hasAltName" class="small-size edit-name-form">
                             <b-form-input v-model="mealFd.altName" class="small-size edit-name-form" :placeholder="mealFd.food.name" @input="updateMealFoodName(mealFd)"></b-form-input>
                                </span>


                  </div>
                  <div class="col-4 col-xl-1">
                    <span class="small-size">Ενέργεια</span> {{ mealFd.food.kcal.toFixed(0) }} kcal
                  </div>
                  <div class="col-4 col-xl-2 food-q">
                    <span class="small-size">Πόσ
                      <b-button variant="link" size="xs"
                                v-b-tooltip.hover
                                :title="mealFd.hideQuantity ? 'Εμφάνιση ποσοτήτων' : 'Απόκρυψη ποσοτήτων'"
                                :disabled="updatingIsodunama"
                                @click="updateHideMealFoodQuantity(mealFd)">
                      <i :class="mealFd.hideQuantity ? 'fe-eye-off' : 'fe-eye'"></i></b-button></span>
                    <!-- Using props -->
                    <b-input-group :append="findFoodMeasure(mealFd.food)">
                      <b-form-input type="number" :value="getFoodQuantity(mealFd)" @change="e => updateMealFoodQuantityByQuantity(e, mealFd)"
                      :disabled="updatingIsodunama"></b-form-input>

                    </b-input-group>

                  </div>
                  <div class="col-4 col-xl-1">
                    <span class="small-size">Υδατ</span> {{mealFd.food.carbohydrates.toFixed(0)}} g
                  </div>
                  <div class="col-4 col-xl-1">
                    <span class="small-size">Πρωτ</span> {{mealFd.food.proteins.toFixed(0)}} g
                  </div>
                  <div class="col-4 col-xl-1">
                    <span class="small-size">Λίπη</span> {{mealFd.food.fats.toFixed(0)}} g
                  </div>
                  <div class="col-8 col-xl-2">
                    <span class="small-size">Ποσότητα</span>
                    <div class="equals-wrapper">
                      <b-button variant="primary" class="decrease-equals" :disabled="updatingIsodunama" @click="updateMealFoodQuantity(mealFd, 'down')">-</b-button>
                      <input v-model="mealFd.quantity" type="text" class="form-control equation-amount" placeholder="Ποσότητα" :disabled="updatingIsodunama" @change="updateMealFoodQuantity(mealFd)"/>
                      <b-button variant="primary" class="increase-equals" :disabled="updatingIsodunama" @click="updateMealFoodQuantity(mealFd, 'up')">+</b-button>
                    </div>
                  </div>
                  <div class="col-4 col-xl-1">

                    <b-button variant="danger" class="meal-action-btn btn-xs" @click="removeMealFood(mealFd)"><i class="fe-trash "></i></b-button>

                  </div>
                </div>

              </b-card-text>
            </b-card>

          </draggable>
        </b-overlay>
      </div>

      <div class="row">
        <div class="col-md-12 mt-3">
          <b-alert :show="isCarbohydration && carbohydrationDay === meal.day" variant="warning">
            <img src="@assets/images/icons/icon-carbohydration.png" width="35px" alt="icon-carbohydration"/>
            Τα στατιστικά παρακάτω έχουν επεξεργαστεί ώστε να δημιουργηθεί η υδατανθράκωση!
          </b-alert>
        </div>

        <div v-if="!isMealTemplate && dietPlanFunc.requiredKcal && !fetchingFoods" class="col-md-6 day-stats">
          <hr>
          <span class="text-muted">Πρόοδος ημέρας</span><br>
          Ενέργεια:
          <b-progress v-b-tooltip max="100" animated :title="calculateCaloriesTitle" >
            <b-progress-bar :value="calculateCaloriesValue"
                            :label="calculateCaloriesPercentage"
                            variant="primary"></b-progress-bar>
          </b-progress>
          <hr>
          <div>
            Υδατάνθρακες
            `<b-progress v-b-tooltip max="100" animated :title="calculateProgressBarTitle('CARB')" >
            <b-progress-bar :value="calculateProgressBarValue('CARB')" :label="calculateProgressBarLabel('CARB')" variant="success"></b-progress-bar>
          </b-progress>`
            <hr>
            Πρωτεϊνες
            <b-progress v-b-tooltip max="100" animated :title="calculateProgressBarTitle('PROTEIN')" >
              <b-progress-bar :value="calculateProgressBarValue('PROTEIN')" :label="calculateProgressBarLabel('PROTEIN')" variant="warning"></b-progress-bar>
            </b-progress>
            <hr>
            Λίπη
            <b-progress v-b-tooltip max="100" animated :title="calculateProgressBarTitle('FAT')" >
              <b-progress-bar :value="calculateProgressBarValue('FAT')" :label="calculateProgressBarLabel('FAT')" variant="danger"></b-progress-bar>
            </b-progress>
          </div>
        </div>
        <div class="col-md-6">
          <hr>

          <div v-if="!isMealTemplate && dietPlanFunc.requiredKcal && dietPlanFunc.nutrients.enableMealGoals && meal.type < 5" class="row pb-2">
            <div class="col-12">
              <span class="text-muted">Πρόοδος γεύματος</span>
            </div>
            <div class="col-md-4 align-content-center">
              <div id="carb-chart">
                <b-tooltip  variant="primary" target="carbs-tooltip">
                  {{statistics.carbs ? statistics.carbs.toFixed(2) : 0 }}g υδατάνθρακες
                </b-tooltip>
                <b-tooltip  variant="primary" target="proteins-tooltip">
                  {{statistics.proteins ? statistics.proteins.toFixed(2) : 0}}g πρωτεϊνες
                </b-tooltip>
                <b-tooltip  variant="primary" target="fats-tooltip">
                  {{statistics.fats ? statistics.fats.toFixed(2) : 0}}g λίπη
                </b-tooltip>
                <vue-circle ref="cbar"
                            id="carbs-tooltip"
                            :progress="0"
                            :size="90"
                            :reverse="false"
                            :fill="{ color: '#1abc9c' }"
                            line-cap="round"
                            empty-fill="rgba(26, 188, 156, .1)"
                            :animation-start-value="0.0"
                            :start-angle="0"
                            insert-mode="append"
                            :thickness="5"
                            :show-percent="true">
                  <p>Υδατ.</p>
                </vue-circle>

              </div>
            </div>
            <div class="col-md-4">
              <div id="protein-chart">
                <vue-circle ref="pbar"
                            id="proteins-tooltip"
                            :progress="0"
                            :size="90"
                            :reverse="false"
                            :fill="{ color: '#f7b84b' }"
                            line-cap="round"
                            empty-fill="rgba(247, 184, 75, .1)"
                            :animation-start-value="0.0"
                            :start-angle="0"
                            insert-mode="append"
                            :thickness="5"
                            :show-percent="true">
                  <p>Πρωτ.</p>
                </vue-circle>
              </div>
            </div>
            <div class="col-md-4">
              <div id="fat-chart">
                <vue-circle ref="fbar"
                            id="fats-tooltip"
                            :progress="0"
                            :size="90"
                            :reverse="false"
                            :fill="{ color: '#f1556c' }"
                            line-cap="round"
                            empty-fill="rgba(241, 85, 108, .1)"
                            :animation-start-value="0.0"
                            :start-angle="0"
                            insert-mode="append"
                            :thickness="5"
                            :show-percent="true">
                  <p>Λίπη</p>
                </vue-circle>
              </div>
            </div>
          </div>
          <div v-if="microNutrients && microNutrients.isEnabled" class="row">
            <div v-for="(key, $index) in Object.keys(microNutrients)" :key="'micro-'+$index">
              <b-badge v-if="key !== 'isEnabled' && microNutrients[key]" :id="'micronutrient' + key" variant="light" size="md" class="m-1 p" style="font-size:0.8rem;">
                {{microNutrientTranslation[key]}}:

                {{dietPlanStats[meal.day].stats.microNutrients[key] ? dietPlanStats[meal.day].stats.microNutrients[key].toFixed(2) : '-' }} <br>

              </b-badge>
            </div>
            <div v-for="(key, $index) in Object.keys(microNutrients)" :key="'dri-'+$index">
              <b-tooltip variant="primary" v-if="key !== 'isEnabled' && dietPlanStats[meal.day].stats.microNutrients[key]" :target="'micronutrient' + key">
                DRI: {{calculateNutrientDri(key, dietPlanStats[meal.day].stats.microNutrients[key])}}
              </b-tooltip>
            </div>
          </div>

          <div v-if="!isRecipe">
            <span class="text-muted">Σημειώσεις</span>
            <b-form-textarea id="input-3" v-model="meal.notes" type="textarea" placeholder="Εισάγετε σημειώσεις για το γεύμα αυτό" rows="3"
                             @blur="updateNotesApiCall"></b-form-textarea>
            <p v-if="isMealTemplate">Ετικέτες:
              <vue-tags-input v-model="tag" :tags="tags" :delete-on-backspace="false" :autocomplete-items="filteredItems"
                              placeholder="Προσθήκη..." :validation="validation" @before-deleting-tag="deleteTag" @before-adding-tag="addTag">
                <template slot="autocomplete-header"><strong>Επιλέξτε μια υπάρχουσα ετικέτα ↓</strong></template>
              </vue-tags-input>
            </p>
          </div>
        </div>

      </div>


    </div>
    <!-- add new food -->
    <div class="col-md-5">
      <b-alert v-if="foodToAvoid.short || foodToAvoid.long" variant="warning" show>
        <h5>Τρόφιμα που αποφεύγει</h5>
        <div v-if="foodToAvoid.short"><span class="font-weight-light">Σύντομο ερωτηματολόγιο:</span> <strong>{{ foodToAvoid.short }}</strong> </div>
        <div v-if="foodToAvoid.long"><span class="font-weight-light">Γενικό ερωτηματολόγιο:</span> <strong>{{ foodToAvoid.long }}</strong></div>
      </b-alert>
      <food-list
          v-if="searchCategory"
          :is-meal-template="isMealTemplate"
          :is-vegan="isVegan"
          :is-fasting="isFasting"
          :local-foods="localFoods"
          :search-category="searchCategory"
          :diet-plan-id="dietPlanId"
          :meal-id="mealId"
          :is-recipe="isRecipe"
          :meal-foods="getAllMealFoods"
          @changeCategory="val => { this.searchCategory = val }"
          @fetchMealFoods="fetchMealFoods(true)">

      </food-list>
    </div>
  </div>
  <div v-else>
    <quill-editor ref="noteEditor" v-model="meal.notes" :options="quillOptions" @blur="updateNotesApiCall"/>

    <div class="mt-3 text-right">
      <save-button @click="updateNotesApiCall({closeAfter:true})" :disabled="meal.notes === initialNotes"></save-button>
    </div>

  </div>



</div>
</template>
