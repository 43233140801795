<template>

    <div class="food-list">
        <div class="search-bar row">
            <div class="col-md-6">
                <input v-model="searchKeyword" type="search" class="form-control search-foods" placeholder="Αναζήτηση..." @input="filterResults"/>
            </div>
            <div class="col-md-6">

                <b-form-select v-model="list" class="select-category" @change="filterResults">
                    <option v-if="!isRecipe" value="0">{{ foodLists[0] }} </option>
                    <option value="1">{{ foodLists[1] }}</option>
                     <option value="2">{{ foodLists[2] }}</option>
                    <option v-if="!isRecipe"value="3">{{ foodLists[3] }}</option>
                    <option value="4">{{ foodLists[4] }}</option>
                  <option value="5">{{ foodLists[5] }}</option>
                    <option value="favourites">{{ foodLists['favourites'] }}</option>
                </b-form-select>
            </div>
            <div class="col-md-6 mt-2">

                <b-form-select v-model="category" class="select-category" @change="() => { this.$emit('changeCategory', category); this.filterResults(); }">
                    <option value="0">Προβολή όλων </option>
                    <option value="1">Πρωινό</option>
                    <option value="2">Δεκατιανό</option>
                    <option value="3">Μεσημεριανό</option>
                    <option value="4">Απογευματινό</option>
                    <option value="5">Βραδινό</option>
                </b-form-select>
            </div>

        </div>
        <div class="row">
            <div class="col-md-10">
                <b-alert :show="insertFoodError !== null" variant="danger" dismissible>{{insertFoodError}}</b-alert>
                <div class="food-list-wrapper p-1">
                    <b-card v-for="(food, $index) of displayingFoods" :key="$index" :class="'category-list-' + food.category" class="shadow-sm food-list-item-wrapper">
                        <b-card-text>
                            <div class="row">

                                <div v-if="food.name" class="col-7 align-center-horizontally text-center">
                                  <b-button variant="link" size="sm" style="float:left;" @click="toggleFavouriteFoodsState(food.id)">
                                    <i :class="'fe-heart' + (favouriteFoods.includes(food.id) ? '-on' : '')"/>
                                  </b-button>

                                  <span v-if="searchKeyword !== ''" class="text-primary-muted" style="font-size:0.7em">
                                         {{foodLists[food.list]}}<br/>
                                  </span>
                                    {{food.name}}

                                  <span v-if="food.category === 5 && food.list === 1">
                                    <b-button variant="link" size="xs" class="ml-1 px-1" @click="loadTrixopoulouInfo(food)"><i class="fas fa-info-circle"></i></b-button>
                                  </span>





                                </div>
                                <div class="col-3 text-right">
                                    <!--                                    <b-button variant="primary" class="decrease-equals" @click="reduceEquation(food)">-</b-button>-->
                                    <b-form-input v-model="food.equations" type="number" min="0.5" step="0.5"
                                                  class="form-control equation-amount equation-amount-list" placeholder="Ποσότητα"/>
                                    <!--                                    <b-button variant="primary" class="increase-equals" @click="increaseEquation(food)">+</b-button>-->

                                </div>
                                <div class="col-2 text-right">
                                    <b-button variant="link" class="add-food-meal-btn"
                                              :disabled="isAddingFood"
                                              @click="addMealFood(food.id, food.equations, food.name)"><i class="fas fa-chevron-right" style="font-size:0.6rem;"></i></b-button>
                                </div>

                            </div>
                        </b-card-text>
                    </b-card>
                    <infinite-loading :identifier="infiniteId" no-more="Δεν υπάρχουν άλλα τρόφιμα" @infinite="infiniteHandler">
                        <div slot="spinner">Φορτώνει...</div>
                        <div slot="no-results">Δεν υπάρχουν τρόφιμα.</div>
                        <div slot="no-more"></div>
                    </infinite-loading>
                </div>
            </div>
            <div class="col-md-2 scrollbar-tools">
                <div class="mb-5"></div>
                <b-button v-b-tooltip.hover.left title="Προβολή όλων"
                          variant="outline-light" class="btn-block meal-category-6" :class="foodType === 5 ? 'is-active' : ''" @click="updateFoodType(5)">
                    <img src="@assets/images/icons/icon-list.png" alt="icon-apple" width="45px"/>
                </b-button>

                <b-button v-b-tooltip.hover.left title="Φρέσκιες"
                        variant="outline-light" class="btn-block meal-category-1" :class="foodType === 0 ? 'is-active' : ''" @click="updateFoodType(0)">
                    <img src="@assets/images/icons/icon-fruit-dark.png" alt="icon-apple" width="45px"/>
                </b-button>
                <b-button v-b-tooltip.hover.left title="Υδατανθρακούχες"
                        variant="outline-light" class="btn-block meal-category-2" :class="foodType === 1 ? 'is-active' : ''" @click="updateFoodType(1)">
                    <img src="@assets/images/icons/icon-amulouxa-dark.png" alt="icon-apple" width="45px"/>
                </b-button>
                <b-button v-b-tooltip.hover.left title="Λιπαρές"
                        variant="outline-light" class="btn-block meal-category-3" :class="foodType === 2 ? 'is-active' : ''" @click="updateFoodType(2)">
                    <img src="@assets/images/icons/icon-fats-dark.png" alt="icon-apple" width="45px"/>
                </b-button>
                <b-button v-b-tooltip.hover.left title="Πρωτεϊνούχες"
                        variant="outline-light" class="btn-block meal-category-4" :class="foodType === 3 ? 'is-active' : ''" @click="updateFoodType(3)">
                    <img v-if="foodType === 3" src="@assets/images/icons/icon-protein-white.png" alt="icon-apple" width="45px"/>
                    <img v-else src="@assets/images/icons/icon-protein-dark.png" alt="icon-apple" width="45px"/>
                </b-button>

                <b-button v-b-tooltip.hover.left title="Σύνθετες"
                        variant="outline-light" class="btn-block meal-category-5" :class="foodType === 4 ? 'is-active' : ''" @click="updateFoodType(4)">
                    <img src="@assets/images/icons/icons-mixed-dark.png" alt="icon-apple" width="45px"/>
                </b-button>
            </div>
        </div>


      <b-modal v-if="trixopoulouContent" ref="trixopoulou-modal" hide-footer centered title="Σύσταση συνταγής" @hidden="trixopoulouContent = {}">

        <b-overlay :show="loadingContent" rounded="sm">
          <div class="">
            <div v-if="!trixopoulouContent.foodName && !loadingContent">
              <p>
                Δυστυχώς δεν βρέθηκε η σύσταση της συνταγής!
              </p>
            </div>
            <h4 class="text-primary mb-3">{{trixopoulouContent.foodName}}</h4>

            <div v-html="markdownToHtml(trixopoulouContent.content)"></div>


          </div>
        </b-overlay>




      </b-modal>
    </div>

</template>

<style lang="scss">
.filled{
  svg{

  }
}
</style>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    import {mapState} from "vuex";
    import axios from "axios";
    import {marked} from 'marked';


    export default{
        components: {InfiniteLoading},
        props:{
            localFoods: {
               type: Array,
               required: true
            },
            isMealTemplate: {
                type: Boolean,
                required: true
            },
            searchCategory: {
                type: String,
                required: true
            },
            initialList: {
                type: Number,
                default: 0
            },
            dietPlanId: {type: String, defaultValue: ''},
            mealId: {type: Number, required: true},
            isVegan: {
                type: Boolean,
                required: true
            },
            isRecipe:{
              type: Boolean,
              default: false
            },
            isFasting: {
                type: Boolean,
                required: true
            },
          mealFoods: {
              type: Array
          }
        },

        data(){
            return{
              trixopoulouContent: {},
              loadingContent: false,
              toastCount: 0,
                searchKeyword: '',
                foodType: 5,
                list: localStorage.getItem('ui.foodModalList') || 0,
                insertFoodError: null,
                category: this.searchCategory,
                limit: 10,
                allFoods: [],
                displayingFoods: [],
                page: 1,
                infiniteId: +new Date(),
                isAddingFood: false,
                foodLists: {
                  '0': 'Πίνακας athlisis',
                  '1': 'Τριχοπούλου (μικροθρεπτικά)',
                  '2': 'USDA Greece',
                  '3': 'Συνταγές',
                  '4': 'Τα τρόφιμά μου',
                  '5': 'aCloud τρόφιμα',
                  'favourites': 'Αγαπημένα τρόφιμα'
                }
            }

        },
        watch:{
            list(newVal){
              localStorage.setItem('ui.foodModalList', newVal);
            }
        },

        computed:{
          ...mapState({
            favouriteFoods: state => state.food.favouriteFoods
          })
        },

        created(){
            this.allFoods = [...this.localFoods.filter(this.foodFilter)];
        },

        methods:{
            filterResults(){
                this.allFoods = [...this.localFoods.filter(this.foodFilter)];
                this.page = 1;
                this.infiniteId += 1;
                this.displayingFoods = [];
            },
            infiniteHandler($state){
                if(this.displayingFoods.length === this.allFoods.length){
                    $state.complete();
                    return;
                }
                this.displayingFoods.push(...this.allFoods.slice((this.page-1)*this.limit, (this.page)*this.limit));
                this.page+=1;
                $state.loaded();

            },
            updateFoodType(num){
                this.foodType = num;
                this.filterResults();
            },
            addMealFood(foodId, quantity, foodName){

                if(!quantity || quantity <= 0  ){
                    this.insertFoodError = 'Παρακαλώ εισάγετε ορθό αριθμό ισοδυνάμων ';
                    return;
                }

                let order = null;
                let maxOrder = Math.max(...this.mealFoods.map(x=>x.order));
                if(maxOrder > 0) order = maxOrder+1;

                let url = this.isMealTemplate ? `/meals/${this.mealId}/foods` : `/dietPlan/${this.dietPlanId}/meals/${this.mealId}/foods`;

                this.isAddingFood = true;
                this.$axios.post(url, {foodId, quantity, foodName, order}).then(result => {
                    this.$emit('fetchMealFoods');
                    // this.fetchMealFoods(true);
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η προσθήκη του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                }).finally(()=>{
                  this.isAddingFood = false;
                });
            },
            toggleFavouriteFoodsState(foodId){
              this.$store.dispatch('food/updateFavouriteFoods', foodId);
              if(this.list === 'favourites'){
                this.filterResults()
              }
            },
            foodFilter(x){
                // check if we are on the favourite foods list
                if(this.list === 'favourites'){
                  if(!this.favouriteFoods.includes(x.id)) return false;
                }else{
                  // check for list first. Athlisis, Trixopoulou or USDA Greece!
                  if(parseInt(this.list) !== parseInt(x.list)){
                    return false;
                  }
                }
                if(this.isRecipe && x.measurementUnit !== 8){
                  return false;
                }
                // check for vegan or fasting first
                if((!x.isVegan && this.isVegan) || (!x.isFasting && this.isFasting) ){
                    return false;
                }
                // if we have a search keyword, we want to ignore food types and categories!
                if(this.searchKeyword !== '' && this.normalizeGreek(x.name.toLowerCase()).includes(this.normalizeGreek(this.searchKeyword.toLowerCase()))){
                    return true;
                }
                // if we have foodType = 5, that means that we want to display all the foods.
                // foodType: ola, freskies, amylouxes, liph, zwikes, suntheta
                if(this.foodType !== 5 && x.category !== this.foodType+1){
                    return false;
                }
                if(!this.normalizeGreek(x.name.toLowerCase()).includes(this.normalizeGreek(this.searchKeyword.toLowerCase()))){
                    return false;
                }
                if(this.category==='0'){
                    return true;

                }else if(this.category==='1' && x.foodMealCategory.isBreakfast){
                    return true;

                }else if(this.category==='2' && x.foodMealCategory.isDekatiano){
                    return true;

                }else if(this.category==='3' && x.foodMealCategory.isMesimeriano){

                    return true;

                }else if(this.category==='4' && x.foodMealCategory.isApogeumatino){

                    return true;

                }else if(this.category==='5' && x.foodMealCategory.isVradino){
                    return true;
                }

                return false;
            },
            normalizeGreek(text) {
              return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            },
            loadTrixopoulouInfo(food){
              let instance = axios.create();

              this.loadingContent = true;
              this.$refs['trixopoulou-modal'].show();
              instance.get('https://cms.athlisis.com/sintages-trixopoulous?foodId=' + food.id,{transformRequest: (data, headers) => {
                  delete headers.common['x-dietitian-token'];
                  delete headers.common['x-access-token'];
                }}).then(res => {
                  if(res.data && Array.isArray(res.data) && res.data.length > 0){
                    this.trixopoulouContent = res.data[0];
                  }
              }).catch(()=>{}).finally(()=>{this.loadingContent = false});
            },
          markdownToHtml(content){
              if(content) return marked(content);
              return '';
          }

        }

    }
</script>
