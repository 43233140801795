<template>
  <div id="cloud-modal">
    <div v-if="canPushToCloud">
      <div class="row">
        <div v-if="dietPlanId || (listing && listing.dietPlanId)" class="col-12">

          <b-form-input v-model="cloud.title" placeholder="Το όνομα που θα υπάρχει στο cloud"></b-form-input>
        </div>
        <div class="col-12 mt-3">

          <b-form-textarea v-model="cloud.text" placeholder="Περιγραφή"></b-form-textarea>
        </div>
        <div class="col-12 mt-3">
          <p class="font-weight-bold">Επιλέξτε ετικέτες (τουλάχιστον 1):  </p>
            <b-form-checkbox-group id="tags" v-model="cloud.tags" :options="tagList" name="tags">

            </b-form-checkbox-group>

        </div>

        <div class="col-12 mt-3">
          <b-button v-if="!listing" variant="primary" @click="pushToCloud">Κοινοποίηση στο cloud</b-button>
          <save-button v-else @click="pushToCloud"></save-button>
        </div>

      </div>
    </div>
    <div v-else class="text-center">
      Δεν μπορείτε να ξανακοινοποίησετε το πλάνο αυτό στο athlisis cloud.

      <b-button variant="primary" class="mt-3" @click="showCloudPlan">Προβολή του cloud πλάνου</b-button>
    </div>
  </div>
</template>

<style lang="scss">
#tags{
  .custom-checkbox{
    width:45%;
    display:inline-block;
    margin-bottom:0.3em;
  }
}
</style>

<script>

import {mapState} from "vuex";

export default{
  props:['dietPlanId', 'recipeId', 'listing'],
  computed:{
    ...mapState({
      cloudTags: state => state.cloud._cloudTags
    }),

    tagList(){
      return this.cloudTags.map(x=>{
        x.value = x.id;
        return x;
      })
    }
  },
  data(){
    return{
      canPushToCloud: false,
      listingId: null,
      cloud:{
        title: '',
        text: '',
        tags: []
      }
    }
  },
  mounted(){
    if(this.dietPlanId) this.fetchDietPlanCloudStatus();
    if(this.recipeId) this.fetchRecipeCloudStatus();

    if(this.listing){
      this.cloud.title = this.listing.title;
      this.cloud.text = this.listing.text;
      this.cloud.tags = this.listing.tags;
      this.canPushToCloud = true;
    }
  },

  methods:{
    fetchDietPlanCloudStatus(){
      this.$axios.get('/cloud/listings/diet-plans/status', {params: {dietPlanId: this.dietPlanId}}).then(res=>{
        this.canPushToCloud = res.data.canCreateNewListing;
        this.listingId = res.data.listingId;

      }).catch(e=> {
        console.log(e);
        this.canPushToCloud = false;
      });

    },
    fetchRecipeCloudStatus(){
      this.$axios.get('/cloud/listings/recipes/status', {params: {recipeId: this.recipeId}}).then(res=>{
        this.canPushToCloud = res.data.canCreateNewListing;
        this.listingId = res.data.listingId;
      }).catch(e=> {
        this.canPushToCloud = false;
      });
    },
    showCloudPlan(){
      this.$router.push({name: 'cloud-listing', params: {listingId: this.listingId}});
    },
    pushToCloud(){
      if(this.listing){
        this.updateListing();
        return;
      }
      if(this.dietPlanId) this.pushDietPlanToCloud();
      if(this.recipeId) this.pushRecipeToCloud();
    },

    pushDietPlanToCloud(){
      if(!this.cloud.title || !this.cloud.text || this.cloud.tags.length === 0){
        this.$notify({group: 'athlisis-notifications', type:'error', title: 'Κάτι πήγε λάθος!', text: 'Παρακαλώ συμπληρώστε όλα τα στοιχεία' +
              ' (όνομα, περιγραφή, τουλάχιστον 1 ετικέτα)'});
        return
      }
      this.$axios.post(`/cloud/creation/diet-plan`, {
        title: this.cloud.title,
        text: this.cloud.text,
        tags: this.cloud.tags,
        dietPlanId: this.dietPlanId}).then(result => {

        this.$router.push({name: 'cloud-listing', params: {listingId: result.data.id}})
        this.$notify({group: 'athlisis-notifications', type:'success',title: 'Επιτυχία!', text: 'Το διατροφικό πλάνο κοινοποιήθηκε στο athlisis cloud.'});
      }).catch(e => {
        this.$notify({group: 'athlisis-notifications', type:'error', title: 'Κάτι πήγε λάθος!', text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });
    },

    pushRecipeToCloud(){
      if(this.cloud.tags.length === 0){
        this.$notify({group: 'athlisis-notifications', type:'error', title: 'Κάτι πήγε λάθος!', text: 'Παρακαλώ συμπληρώστε όλα τα στοιχεία' +
              ' (τουλάχιστον 1 ετικέτα)'});
        return
      }
      this.$axios.post(`/cloud/creation/recipe`, {
        text: this.cloud.text,
        tags: this.cloud.tags,
        recipeId: this.recipeId}).then(result => {

        this.$router.push({name: 'cloud-listing', params: {listingId: result.data.id}})
        this.$notify({group: 'athlisis-notifications', type:'success',title: 'Επιτυχία!', text: 'Η συνταγή κοινοποιήθηκε στο athlisis cloud.'});
      }).catch(e => {
        this.$notify({group: 'athlisis-notifications', type:'error', title: 'Κάτι πήγε λάθος!', text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });
    },

    updateListing(){
      this.$axios.put(`/cloud/listings/${this.listing.id}`, {
        title: this.cloud.title,
        text: this.cloud.text,
        tags: this.cloud.tags}).then(result => {

          this.$emit('updated');
        this.$notify({group: 'athlisis-notifications', type:'success',title: 'Επιτυχία!', text: 'Η καταχώρηση ενημερώθηκε επιτυχώς.'});
      }).catch(e => {
        console.log(e);
        this.$notify({group: 'athlisis-notifications', type:'error', title: 'Κάτι πήγε λάθος!', text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });
    }
  }
}
</script>
