<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import addFoodModal from "@views/dietPlan/details/addFoodModal/addFoodModal";
import {mapState} from "vuex";
import printHeader from '@components/print/header';
import PrintMixin from "@src/mixins/PrintMixin";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import domtoimage from "@assets/js/dom-to-image-more-fixed.minified";
import CloudModal from "@views/dietPlan/details/CloudModal";


export default {
  components: {quillEditor, addFoodModal, printHeader, vueDropzone: vue2Dropzone, CloudModal},
  page: {title: 'Συνταγές'},

  mixins: [PrintMixin],
  data() {
    return {
      fields: [
        // A column that needs custom formatting
        { key: 'name', label: 'Όνομα' },
        // A virtual column made up from two fields
        { key: 'quantity', label: 'Ποσότητα' }
      ],
      allSelected: false,
      recipe: null,
      recipeId: null,
      isRecipeLoaded: false,
      initialRecipe: null,
      foodUpdated: false,
      isPrinting: false,
      isPrintingLabel: false,
      output: null,
      selectedMicronutrients: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API}/general/image-upload`,
        thumbnailWidth: 150,
        maxFilesize: 12,
        headers: { "x-access-token": JSON.parse(window.localStorage.getItem('auth.token')) },
        maxFiles: 1,
        resizeHeight: 350,
        resizeQuality: 0.7,
        dictDefaultMessage: 'Σύρτε εδώ μια εικόνα ή πατήστε πάνω στο πλαίσιο',
      },
      changeImage: false,
      showCloudModal: false
    }
  },

  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    }),
    recipeChanged(){
      return this.initialRecipe && JSON.stringify(this.recipe) !== this.initialRecipe;
    },
    imageChanged(){
      return this.recipe.image !== JSON.parse(this.initialRecipe).image;
    },
    foods(){
      return this.$store.getters['food/foods'];
    }
  },


  async created(){
    await this.fetchFoods(); // load foods from vuex store or await for api call!
    this.recipeId = this.$route.params.recipeId;
    this.fetchRecipe();
  },
  methods: {

    openPushToCloudModal(){
      this.showCloudModal = true;
    },
    toggleMicroNutrientSelection(){

      if(this.selectedMicronutrients.length === Object.keys(this.recipe.food.microNutrients).length){
        this.selectedMicronutrients = []
      }else{
        this.selectedMicronutrients = [...Object.keys(this.recipe.food.microNutrients)]
      }

    },

    updateRecipeFood(){
      this.$axios.put(`/recipes/${this.recipeId}/recalculate`).then(async res=>{
        await this.$store.dispatch('food/updateFood', res.data);
        this.recipe.food = res.data;
        this.initialRecipe = JSON.stringify(this.recipe);
        this.selectedMicronutrients = Object.keys(this.recipe.food.microNutrients);

      }).catch(e=>{
      });
    },
    fetchRecipe(){
      this.$axios.get(`/recipes/${this.recipeId}`, ).then(result => {
        this.recipe = result.data;
        this.$store.commit('ui/setActiveItem', {
          value: this.recipe.name,
          index: 0
        });
        this.isRecipeLoaded = true;
        this.initialRecipe = JSON.stringify(this.recipe);
        this.selectedMicronutrients = Object.keys(this.recipe.food.microNutrients);
      }).catch(e => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });

    },
    deleteRecipe(recipeId){
      let message = {
        title: 'Θέλετε σίγουρα να διαγράψετε τη συνταγή αυτή; ',
        body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσετε να επαναφέρετε αυτή τη συνταγή, και θα διαγραφεί και το αντίστοιχο τρόφιμο.'
      };
      this.$dialog.confirm(message, {
        loader: true,
        okText: 'Διαγραφή',
        cancelText: 'Ακύρωση',
        backdropClose: true
      }).then((dialog) => {
        this.$axios.delete(`/recipes/${recipeId}`).then(async result => {
          dialog.close();
          await this.$store.dispatch('food/deleteFood',  this.recipe.food.id);
          this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: 'Το πρότυπο γεύμα διαγράφηκε επιτυχώς!'});
          this.$router.push({name: 'recipes'});
        }).catch(e => {
          this.$notify({group: 'athlisis-notifications', type: 'error', title: this.$t('errors.title'), text: 'Η διαγραφή απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
        });
      }).catch(()=>{});
    },
    updateRecipe(){

      this.$axios.put(`/recipes/${this.recipeId}`, {name: this.recipe.name,
        execution: this.recipe.execution,
        displayType: this.recipe.displayType, persons: this.recipe.persons, image: this.recipe.image}).then(result => {
        if(Array.isArray(this.recipe.foods) && this.recipe.foods.length > 0) this.updateRecipeFood();

        this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: 'Η συνταγή ανανεώθηκε επιτυχώς!'});
      }).catch(e => {
        this.$notify({group: 'athlisis-notifications', type: 'error', title: this.$t('errors.title'), text: 'Η ανανέωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });
    },
    openFoodModal(){
      this.updateRecipe();
      this.$refs['meal-template-food-modal'].show();
    },

    viewRecipe(id){
      this.$router.push('/recipes/'+id);
    },
    getQuantity(food){
      return food['mealFoods.quantity'] * food.quantity + ' gr';
    },
    fetchRecipeFoods(){
      this.$axios.get(`/recipes/${this.recipeId}/foods`, ).then(result => {
        this.recipe.foods = result.data;
        this.isRecipeLoaded = true;
        this.initialRecipe = JSON.stringify(this.recipe);
      }).catch(e => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });
    },
    printRecipe(type){
      this.isPrinting = true;

      this.$nextTick(()=>{
        let divToPrint = this.$refs['print-area'].outerHTML;
        this.isPrinting=false;
        // remove empty paragraphs.
        divToPrint = divToPrint.replaceAll("<p><br></p>", "");
        divToPrint = divToPrint.replaceAll('display: none', "");
        this.print(divToPrint, type);
      })
    },
    async printLabel() {
      this.isPrintingLabel = true;
      const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL'
      }

      let name = this.recipe.name;
      const dataUrl = await domtoimage.toPng(el, {scale: 4});

      let a = document.createElement("a"); //Create <a>
      a.href = dataUrl //Image Base64 Goes here
      a.download = name + ".png"; //File name Here
      a.click(); //Downloaded file
      this.isPrintingLabel = false;
    },
    labelOptionsModal(){
      this.$refs['selected-micro-nutrients'].show()
    },
    imageUploaded(res){
      if(res.xhr && res.xhr.response){
        this.recipe.image = res.xhr.response;
        this.$refs['uploadRecipeImage'].removeAllFiles()
        this.changeImage=false;

      }

    },

  }
}

</script>
<style lang="scss">
#recipe-wrapper{
  .ql-editor{
    min-height: 150px;
  }
  .custom-control-label{
    font-weight:300;
  }
  .recipe-card-wrapper{
    background:#fff;
    border-radius:12px;
    .recipe-print-title{
      background:$color_primary;
      padding: 15px 0;
    }
    .recipe-print-content{
      padding:10px;

      tr{
        border-bottom:1px dotted #6c757d;
      }
    }
    .micronutrient-table{
      font-size:0.8em;
    }
    .recipe-print-footer{
      padding:15px 0;
      margin-top:15px;
      background:$color_primary;
      color:#fff!important;
      border-bottom-left-radius:12px;
      border-bottom-right-radius:12px;
    }
    .print-label-btn{
      background:#fff;
      border-radius:60px;
      color:$color_primary;
      margin-right:15px;
      &:hover{
        background:#f3f3f3;
      }
    }
  }



}
.multi-select-nutrients{
  .custom-control-label{
    font-weight:300!important;
  }
}
</style>
<template>
  <div id="recipe-layout">
    <div id="recipe-wrapper">

      <div v-if="recipe" class="row">
        <div class="col-md-9">
          <b-card>
            <b-card-text>
              <div class="grid grid-cols-2 gap-5 align-items-center mb-4">
                <div>
                  <b-button v-if="!isEducational" v-b-tooltip.hover variant="primary" title="πρότυπο" @click="openPushToCloudModal">
                    <span><i class="fa fa-check-circle"></i> Κοινοποίηση στο aCloud</span>
                  </b-button>
                </div>
                <div class="text-right">
                  <b-button variant="primary" title="Εκτύπωση συνταγής"
                            :disabled="printLoading"
                            @click="printRecipe(printTypes.print)"><span class="icon-printer"></span></b-button>
                  <b-button variant="outline-primary" title="Αποθήκευση συνταγής σε PDF"
                            :disabled="printLoading"
                            class="ml-1" @click="printRecipe(printTypes.save)"><span class="fas fa-file-pdf"></span></b-button>
                </div>
              </div>
              <div class="row">
                <div  class="col-md-12">
                  <b-form-group v-if="!isEducational" id="image" label="Φωτογραφία συνταγής:" label-for="title">
                    <div class="row">
                      <div class="col-md-12 pt-2 pb-3">
                        <b-alert v-if="imageChanged" variant="warning" show>Μην ξεχάσετε να πατήσετε <strong>"{{$t('saveChanges')}}"</strong></b-alert>
                        <img v-if="recipe.image" :src="recipe.image" height="300" class="mr-2" />
                        <b-button v-if="recipe.image" variant="light" @click="changeImage = !changeImage" class="mr-2">Αλλαγή εικόνας</b-button>
                        <b-button v-if="recipe.image" variant="danger" @click="recipe.image = null">Διαγραφή εικόνας</b-button>
                        <vue-dropzone v-show="!recipe.image || changeImage" id="dropzone" ref="uploadRecipeImage" :options="dropzoneOptions" class="mt-3 mb-3"
                        @vdropzone-complete="imageUploaded"></vue-dropzone>
                         </div>
                    </div>
                  </b-form-group>
                  <b-form-group id="title" label="Όνομα συνταγής:" label-for="title">
                    <b-form-input id="title" v-model="recipe.name" type="text"></b-form-input>
                  </b-form-group>

                  <label>Οδηγίες εκτέλεσης:</label>
                  <quill-editor
                      ref="noteEditor"
                      v-model="recipe.execution"
                      :options="quillOptions"/>

                  <div class="mt-3">
                    <label>Εισαγωγή τροφίμου σε διατροφικά πλάνα:</label>
                    <b-form-group>
                      <b-form-radio-group id="select-display-type" v-model="recipe.displayType">
                        <b-form-radio value="0">Ανά 100γρ.</b-form-radio>
                        <b-form-radio value="1">Ανά μερίδα</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                  <div v-if="parseInt(recipe.displayType) === 1" class="mt-3">
                    <label>Για πόσες μερίδες είναι φτιαγμένη η συνταγή;</label>
                    <select id="select-persons" v-model="recipe.persons" class="custom-select">
                      <option v-for="i in (1,36)" :value='i'>{{ i }} {{ i === 1 ? 'Μερίδα' : 'Μερίδες'}}</option>
                    </select>
                  </div>

                </div>

                <div v-if="recipe && recipe.foods" class="col-md-12 mt-3 recipe-food-wrapper">
                  <label>Υλικά:</label>
                  <div class="recipe-foods">
                    <b-table striped bordered hover :items="recipe.foods" :fields="fields">
                      <!-- A custom formatted column -->
                      <template #cell(name)="data">{{data.item["mealFoods.hasAltName"] ? data.item["mealFoods.altName"]: data.item.name}}</template>
                      <template #cell(quantity)="data"><strong>{{getQuantity(data.item)}}</strong></template>
                    </b-table>
                  </div>

                </div>
                <div class="col-md-12">
                  <b-button variant="primary" size="xs" @click="openFoodModal">Επεξεργασία / Προσθήκη υλικών</b-button>
                </div>

                <div class="col-md-12">
                  <b-form-group id="button-group" class="text-right">

                    <delete-button class="mr-2" @click="deleteRecipe(recipeId)"></delete-button>
                    <save-button @click="updateRecipe" :disabled="!recipeChanged"></save-button>
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-md-3">
          <div>
            <!-- SOURCE -->
            <div v-if="recipe.food" id="label-print" ref="printMe" >
              <div class="recipe-card-wrapper">
                <div class="recipe-print-title pb-3 text-center" style="border-top-left-radius:20px; border-top-right-radius:20px">
                  <div v-if="!isPrintingLabel" class="text-center pb-2">
                    <b-button size="xs" variant="default" class="print-label-btn" @click="printLabel"><span class="icon-cloud-download"></span> Αποθήκευση ετικέτας</b-button>
                    <b-button size="xs" variant="default" class="print-label-btn" @click="labelOptionsModal"><i class="icon-settings"></i> Μικροθρεπτικά</b-button>
                  </div>
                  <span class="text-white mb-0 pb-0">Διατροφική αξία</span>
                  <h2 class="text-white mt-0 pt-0">{{recipe.name}}</h2>
                  <span class="bg-white  p-1 pr-2 pl-2 text-black-100" style="border-radius:12px;color:#000;">Μερίδα <strong>{{recipe.food.quantity.toFixed(2).replace(/[.,]00$/, "")}} gr</strong></span>
                 </div>
                <div class="recipe-print-content m-auto " style="width:90%">
                  <h4>Βασικά Στοιχεία</h4>
                  <table style="width:100%">
                    <tr>
                      <td>Ενέργεια</td>
                      <td class="text-right">{{ recipe.food.kcal.toFixed(2) }} kcal</td>
                    </tr>
                    <tr>
                      <td>Υδατάνθρακες</td>
                      <td class="text-right">{{ recipe.food.carbohydrates.toFixed(2) }} g</td>

                    </tr>
                    <tr>
                      <td>Πρωτεϊνες</td>
                      <td class="text-right">{{ recipe.food.proteins.toFixed(2) }} g</td>

                    </tr>
                    <tr>
                      <td>Λίπη</td>
                      <td class="text-right">{{ recipe.food.fats.toFixed(2) }} g</td>
                    </tr>
                  </table>

                  <h4 class="mt-3" v-if="selectedMicronutrients.length > 0">Μικροθρεπτικά Στοιχεία</h4>
                  <table style="width:100%" class="micronutrient-table">
                    <tr v-for="nutrient in selectedMicronutrients" :key="nutrient"  v-if="recipe.food.microNutrients[nutrient]">
                      <td>{{ microNutrientTranslation[nutrient] }}</td>
                      <td class="text-right">{{ recipe.food.microNutrients[nutrient] ? recipe.food.microNutrients[nutrient].toFixed(2) : '0.00' }}</td>
                    </tr>
                  </table>
                </div>
                <div class="recipe-print-footer">
                  <div class="text-center">
                    <div>Διατροφολόγος: <strong>{{user.firstName + ' ' + user.lastName}}</strong> </div>
                    <span style="font-size:0.8em">www.athlisis.com</span>
                  </div>
                </div>
              </div>
            </div>

            <div id="label-canvas"></div>
          </div>
        </div>
      </div>

    </div>

    <b-modal v-if="recipe && recipe.food" id="selected-micro-nutrients" ref="selected-micro-nutrients" size="small" title="Επιλογή μικροθρεπτικών ετικέτας" hide-footer>
      <p>Επιλέξτε τα μικροθρεπτικά που θέλετε να φαίνονται στην ετικέτα σας:</p>

      <div class="text-right">
        <b-button variant="link" @click="toggleMicroNutrientSelection">
          {{selectedMicronutrients.length === Object.keys(recipe.food.microNutrients).length ? 'Αποεπιλογή όλων' : 'Επιλογή όλων'}}
        </b-button>
      </div>

      <b-form-checkbox-group v-model="selectedMicronutrients" name="some-radios" class="multi-select-nutrients">
        <b-form-checkbox v-for="key in Object.keys(recipe.food.microNutrients)" :key="key" :value="key" style="width:45%;">{{microNutrientTranslation[key]}}</b-form-checkbox>
      </b-form-checkbox-group>
    </b-modal>
    <b-modal v-if="recipe" id="meal-template-food-modal" ref="meal-template-food-modal" size="full" title="Επεξεργασία γεύματος"
             scrollable hide-footer  body-class="p-3" @hidden="fetchRecipeFoods">

      <add-food-modal :meal-id="recipe.meal.id"
                      :foods="foods"
                      :is-recipe="true"
                      :is-meal-template="true"
                      @food-changed="updateRecipeFood"
      ></add-food-modal>

    </b-modal>
    <div v-show="isPrinting" v-if="recipe" id="print-area" ref="print-area">
      <print-header v-show="isPrinting" :title="'Συνταγή: <strong>' + recipe.name +'</strong>'"/>

      <div class="note-body">
        <div style="text-align:center;padding:1em 0;">
          <img v-if="recipe.image" :src="recipe.image" style="border-radius:12px;"/>
        </div>

        <h3>Υλικά:</h3>
        <b-table striped bordered hover :items="recipe.foods" :fields="fields" style="text-align:left!important;">
          <!-- A custom formatted column -->
          <template #cell(name)="data">{{data.item["mealFoods.hasAltName"] ? data.item["mealFoods.altName"]: data.item.name}}</template>
          <template #cell(quantity)="data"><strong>{{getQuantity(data.item)}}</strong></template>
        </b-table>
        <h3>Οδηγίες εκτέλεσης:</h3>
        <div v-html="recipe.execution"></div>
      </div>

    </div>
    <b-modal v-model="showCloudModal" id="cloud-modal" ref="cloud-modal" size="md" title="Κοινοποίηση στο athlisis cloud" hide-footer>


      <cloud-modal v-if="showCloudModal" :recipe-id="recipeId" />

    </b-modal>
  </div>
</template>
